import { Container, Row, Line, Data } from './styles';

const DataPix = ({ date }) => {
    let novaData = new Date(date).toLocaleString().split(',')[0];
    return (
        <Container>
            <Line />
            <Row><Data>{date ? novaData : 'Data e hora'}</Data></Row>
        </Container>
    );
}

export default DataPix;