import styled from 'styled-components';

//cola no fim da pagina
export const Container = styled.div`
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  margin-top: 20px;
  height: 50%;
  display: flex;
  flex-direction: column;
  `;

export const H1 = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  font-family: "Source Sans 3", sans-serif;
  `;
