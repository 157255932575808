import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import { Container, LoginBlock, Column, Row, StyledInput, StyledButton, H1 } from './styles.js';
import { FaPix } from "react-icons/fa6";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios.post(`${process.env.REACT_APP_BASE_URL}/login/user`, {
      userName: data.get('user'),
      password: data.get('password')
    })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('name', response.data.user.name);
          localStorage.setItem('admin', response.data.user.admin || false);
          window.location.href = '/';
        }
      })
      .catch(function (error) {
        setLoginFailed(true);
        data.set('user', '');
        data.set('password', '');
        const inputs = document.querySelectorAll('input');
        inputs.forEach(input => {
          input.value = '';
        });
        console.log(error);
      });
  };
  const [loginFailed, setLoginFailed] = useState(false);
  return (
    <Container>
      <Stack sx={{ width: 'auto' }} spacing={2} position="absolute" top="0" right="0" padding={5} display={loginFailed ? 'block' : 'none'}>
        <Alert variant="filled" severity="error">
          Usuário e/ou senha inválidos
        </Alert>
      </Stack>
      <LoginBlock>
        <Row>
          <Column bgcolor="#2b2b2b">
            <Avatar sx={{ m: 1, bgcolor: '#22CF37', height: '100px', width: '100px' }}>
              <FaPix style={{ height: '75%', width: '75%' }} />
            </Avatar>
          </Column>
          <Column padding="2rem">
            <H1>JIT Pag</H1>
            <form onSubmit={handleSubmit}>
              <StyledInput type="text" name="user" placeholder="Usuário" />
              <StyledInput type="password" name="password" placeholder="Senha" />
              <StyledButton type="submit">
                Entrar
              </StyledButton>
            </form>
          </Column>
        </Row>
      </LoginBlock>
    </Container>
  );
}
