import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  overflow-y: auto;
  @media (orientation: portrait) {
    width: 90%;
  }
  `;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
padding: 0 1rem;
gap: ${props => props.gap || '0'};
margin-top: ${props => props.mt || '0'};
`;
export const PositionFixed = styled.div`
width: 100%;
position: sticky;
top: 0;
padding-top: 1.5rem;
background-color: #161616;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;
