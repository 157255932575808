import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #161616;
  font-family: "Source Sans 3", sans-serif;
  @media (orientation: portrait) {
    height: 100%;
  }
`;

// Source sans pro
export const H1 = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 5%;
  font-family: 'Source Sans Pro', sans-serif;

  `;

export const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 95%;
  flex-direction: row;
    @media (orientation: portrait) {
    flex-direction: column;
  }
`;
export const ContainerCollumContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  //se admin for true 
  justify-content: space-between;
  ${(props) => props.admin === true && `
    justify-content: center;
  `}
`;


export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 5%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
    @media(orientation: portrait) {
    margin-top: 1rem;
  }
`;