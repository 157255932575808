import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, ContainerCollumContent, H1, Header, Row } from "./styles";
import MenuLateralDireito from "../../components/MenuLateralDireito";
import LastPix from "../../components/LastPix";
import BasicBars from "../../components/Chart";
import { IoMdExit } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Home = () => {
  const pixNull = {
    pixs: [
      {
        name: "Nome do cliente",
        date: "Tempo",
        value: 0,
      },
    ],
  };
  const [pixData, setPixData] = useState(pixNull);
  const [isFuncionandoServer, setIsFuncionandoServer] = useState(false);
  const [mesValor, setMesValor] = useState([]);
  let token = localStorage.getItem("token");
  let admin = (localStorage.getItem("admin") === "true");
  if (!token) {
    window.location.href = "/login";
  }
  useEffect(() => {
    const dataAtual = new Date();
    const anoPassado = new Date(dataAtual.getFullYear() - 2, dataAtual.getMonth(), dataAtual.getDate());

    const fetchData = async () => {
      try {

        const response2 = await axios.get(
          `${BASE_URL}/`,
        );
        if (response2.status === 200) {
          setIsFuncionandoServer(true);
        }

        const response = await axios.post(
          `${BASE_URL}/pix/data?page=0`,
          {
            startTime: anoPassado.toISOString(),
            endTime: dataAtual.toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setPixData(response.data);
        if (admin) {
          const response3 = await axios.get(
            `${BASE_URL}/groupbymonth/data`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setMesValor(response3.data);
        }
      } catch (error) {
        console.error("Erro ao buscar dados do servidor:", error);
        setIsFuncionandoServer(false);
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Header>
        <H1>JIT Pag</H1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ color: "#fff", paddingRight: "1rem", fontWeight: "bold" }}>Olá, {localStorage.getItem("name") || "Usuário"}</p>
          <IoMdExit
            style={{ marginRight: "1rem", cursor: "pointer", color: "#fff" }}
            onClick={() => {
              localStorage.removeItem("token");
              window.location.href = "/login";
            }}
          />
        </div>
      </Header>
      <Row>
        <ContainerCollumContent admin={admin}>
          <LastPix lastPix={pixData.pixs[0]} admin={admin} /> {/*35% de altura*/}
          {admin && <BasicBars mesValor={mesValor} totalValue={pixData.totalValue || `0.00`} />}
        </ContainerCollumContent>
        <MenuLateralDireito Pixs={pixData.pixs} isFuncionandoServer={isFuncionandoServer} />
      </Row>
    </Container>
  );
};

export default Home;
