import React, { useState, useEffect } from 'react';
import { Container, Title, Row, PositionFixed } from './styles.js';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from "react-icons/md";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DataPix from '../DataPix';
import Pix from '../Pix';

const MenuLateralDireito = ({ Pixs, isFuncionandoServer }) => {
    const [datesWithPixs, setDatesWithPixs] = useState([]);
    const [pesquisaAtiva, setPesquisaAtiva] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const groupPixByDate = () => {
            const groupedPixs = [];
            let lastDate = null;
            if (!pesquisaAtiva) {
                Pixs.forEach((pix) => {
                    const pixDate = new Date(pix.date).toDateString();
                    if (pixDate !== lastDate) {
                        groupedPixs.push({ date: pixDate, type: 'date' });
                        lastDate = pixDate;
                    }
                    groupedPixs.push({ ...pix, type: 'pix' });
                });
                setDatesWithPixs(groupedPixs);
            }
        };

        groupPixByDate();
    }, [Pixs]);

    const handleSearch = (e) => {
        const search = e;
        setSearchTerm(search);
        if (search === '' && startDate === '' && endDate === '') {
            setPesquisaAtiva(false);
            return;
        } else {
            setPesquisaAtiva(true);
        }
        filterPixs(search, startDate, endDate);
    };

    const handleStartDateChange = (e) => {
        const date = e.target.value;
        setStartDate(date);
        if (searchTerm === '' && date === '' && endDate === '') {
            setPesquisaAtiva(false);
        } else {
            setPesquisaAtiva(true);
        }
        filterPixs(searchTerm, date, endDate);
    };

    const handleEndDateChange = (e) => {
        const date = e.target.value;
        setEndDate(date);
        if (searchTerm === '' && startDate === '' && date === '') {
            setPesquisaAtiva(false);
        } else {
            setPesquisaAtiva(true);
        }
        filterPixs(searchTerm, startDate, date);
    };

    const handleClearDates = () => {
        setStartDate('');
        setEndDate('');
        setSearchTerm('');
        setPesquisaAtiva(false);
        filterPixs(searchTerm, '', '');
    };

    const filterPixs = (search, start, end) => {
        const filteredPixs = Pixs.filter((pix) => {
            const pixDate = new Date(pix.date);
            const isWithinDateRange = (!start || pixDate >= new Date(start)) &&
                (!end || pixDate <= new Date(end));
            return (pix.name.toLowerCase().includes(search.toLowerCase()) ||
                (pix.value / 100).toFixed(2).toString().includes(search) ||
                pixDate.toDateString().toLowerCase().includes(search.toLowerCase())) && isWithinDateRange;
        });

        const groupedPixs = [];
        let lastDate = null;

        filteredPixs.forEach((pix) => {
            const pixDate = new Date(pix.date).toDateString();
            if (pixDate !== lastDate) {
                groupedPixs.push({ date: pixDate, type: 'date' });
                lastDate = pixDate;
            }
            groupedPixs.push({ ...pix, type: 'pix' });
        });
        console.log(groupedPixs);
        setDatesWithPixs(groupedPixs);
    };

    return (
        <Container>
            <PositionFixed>
                <Row>
                    <Title>Últimas transações</Title>
                    {isFuncionandoServer ? <FaRegCheckCircle style={{ color: '#22CF37', fontSize: '2vh' }} /> : <MdErrorOutline style={{ color: '#FF0000', fontSize: '2vh' }} />}
                </Row>

                <Paper
                    component="form"
                    sx={{
                        p: '2px 0px 0px 4px', display: 'flex', alignItems: 'center', backgroundColor: '#323232', borderRadius: '20px', width: '95%'
                    }}
                >

                    <InputBase
                        sx={{ ml: 1, flex: 1, color: '#fff' }}
                        placeholder="Buscar por nome ou valor"
                        inputProps={{ 'aria-label': 'Buscar por nome' }}
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchTerm}
                    />
                    <IconButton type="button" sx={{ p: '10px', color: '#000', backgroundColor: '#fff' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
                <Row gap={`1rem`} mt={`1rem`}>
                    <Paper
                        component="form"
                        sx={{
                            p: '5px', display: 'flex', alignItems: 'center', backgroundColor: '#323232', borderRadius: '20px', width: '95%'
                        }}
                    >
                        <InputBase
                            type="date"
                            sx={{ ml: 1, flex: 1, color: '#fff' }}
                            placeholder="Data de início"
                            inputProps={{ 'aria-label': 'Data de início' }}
                            onChange={handleStartDateChange}
                            value={startDate}
                        />
                    </Paper>
                    <Paper
                        component="form"
                        sx={{
                            p: '5px', display: 'flex', alignItems: 'center', backgroundColor: '#323232', borderRadius: '20px', width: '95%'
                        }}
                    >
                        <InputBase
                            type="date"
                            sx={{ ml: 1, flex: 1, color: '#fff' }}
                            placeholder="Data de término"
                            inputProps={{ 'aria-label': 'Data de término' }}
                            onChange={handleEndDateChange}
                            value={endDate}
                        />
                    </Paper>
                    <IconButton type="button" sx={{ p: '10px', color: '#000', backgroundColor: '#fff' }} aria-label="clear" onClick={handleClearDates}>
                        <ClearIcon />
                    </IconButton>
                </Row>
            </PositionFixed>

            {
                datesWithPixs.map((item, index) => (
                    item.type === 'date' ? (
                        <DataPix key={index} date={item.date} />
                    ) : (
                        <Pix key={index} value={item.value} name={item.name} currentValue={item.currentValue} />
                    )
                ))
            }
        </Container >
    );
};

export default MenuLateralDireito;
