import { createGlobalStyle } from 'styled-components'
export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-size: 1rem;
  }

  body {
    -webkit-font-smoothing: antialiased;
    color: black;
    font-family: "Source Sans 3", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    flex-direction: column;
    display: flex;
    flex: 1;
  }
   svg {
    height: 24px;
    width: 24px;
  }

  body, input, button, span, textarea {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
}

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
`

