import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100vh;
  background-color: #161616;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Source Sans 3", sans-serif;
`;

export const LoginBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 45%;
  background-color: #323232;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background-color: ${(props) => props.bgcolor || "transparent"};
  padding: ${(props) => props.padding || "0"};
    @media (orientation: portrait) {
      border-radius: 10px 10px 0 0;
    }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
    @media (orientation: portrait) {
      flex-direction: column;
    }
  `;



export const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #484848;
  color: #fff;
  font-size: 1rem;

  &::placeholder {
    color: #b0b0b0;
  }

  &:focus {
    outline: none;
    border-color: #22CF37;
    box-shadow: 0 0 5px rgba(34, 207, 55, 0.5);
  }
`;

export const StyledButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #22CF37;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1bb12e;
  }
  @media (orientation: portrait) {
    margin-bottom: 4rem;
  }
`;

export const H1 = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-family: "Source Sans 3", sans-serif;
  font-weight: bold;
  margin-bottom: 4rem;
`;