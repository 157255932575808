import styled from 'styled-components';

export const Container = styled.div`
  height: 2vh;
  width: 90%;
  font-family: "Source Sans 3", sans-serif;
  font-size: 1.5vh;
  margin: 2vh 0vh;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: ${props => props.justifyContent || 'space-between'};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

`;


export const Data = styled.h2`
  color: #fff;
  font-size: 1.4vh;
  font-weight: 500;
`;


export const Line = styled.div`
  width: 100%;
  height: 0.1vh;
  background-color: #323232;
  margin: 1vh 0vh;
`;