import { Container, Header, Name, Time, Title, AmountReceived, Line, ValuesContainer, ValueLabel, Value, DataHora, Row, SimpleLabel, Divider } from './styles';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
const LastPix = ({ lastPix, admin }) => {
  function timeAgo(timestamp) {
    const previousTime = new Date(timestamp);
    const currentTime = new Date();

    const timeDifference = currentTime - previousTime;
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    if (minutesDifference < 60) {
      return `${minutesDifference} minutos atrás`;
    } else {
      const hoursDifference = Math.floor(minutesDifference / 60);
      return `${hoursDifference} horas atrás`;
    }
  }
  return (
    <Container>
      <Header>
        <Title>Valor Recebido</Title>
        <Time> {lastPix.date ? timeAgo(lastPix.date) : 'Tempo'}</Time>
      </Header>
      <DataHora> {lastPix.date ? new Date(lastPix.date).toLocaleString() : 'Data e hora'}</DataHora>
      <Row>
        <Name>{lastPix.name ? lastPix.name : 'Nome do cliente'}</Name>
        <AmountReceived>R${lastPix.value ? (lastPix.value / 100).toFixed(2) : '0.00'}</AmountReceived>
      </Row>
      {admin === true &&
        <>
          <ValueLabel>Valores em caixa</ValueLabel>
          <Line></Line>
          <ValuesContainer>
            <div>
              <SimpleLabel><FaCaretUp /> Valor total</SimpleLabel>
              <Value>R$ {lastPix.currentValue ? (lastPix.currentValue / 100).toFixed(2) : '0.00'}</Value>
            </div>
            <Divider></Divider>
            <div>
              <SimpleLabel><FaCaretDown /> Valor anterior</SimpleLabel>
              <Value>R$ {lastPix.previousValue ? (lastPix.previousValue / 100).toFixed(2) : '0.00'}</Value>
            </div>
          </ValuesContainer>
        </>
      }
    </Container>
  );
};

export default LastPix;