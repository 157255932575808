import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  height: auto;
  margin-top: 20px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

`;

const Title = styled.div`
  font-weight: bold;
`;

const Time = styled.div`
  font-size: 12px;
  color: #bbb;
`;

const Name = styled.h2`
  margin: 10px 0;
  font-size: 35px;
  font-weight: bold;
`;

const AmountReceived = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin: 10px 0;
`;

const Line = styled.div`
  background-color: #444;
  padding: 5px;
  border-radius: 5px;
`;

const ValuesContainer = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 30px;
`;

const ValueLabel = styled.div`
  font-size: 20px;
  color: #fff;
  margin-bottom: 5px;
  font-weight: bold;
  `;

const SimpleLabel = styled.div`
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
  `;

const Value = styled.div`
  font-size: 16px;
`;
const DataHora = styled.div`
  font-size: 12px;
  color: #bbb;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  height: 60px;
  width: 1px;
  background-color: #666;
`;

export {
  Container,
  Header,
  Name,
  Time,
  Title,
  AmountReceived,
  Line,
  ValuesContainer,
  ValueLabel,
  Value,
  DataHora,
  Row,
  SimpleLabel,
  Divider
};