import { Container, Row, Column, Icone, DadosPessoais, Nome, Recebimento, Saldo } from './styles';
import { FaPix } from "react-icons/fa6";

const Pix = ({ value, name, currentValue }) => {
    return (
        <Container>
            <Row>
                <Column>
                    <Row>
                        <Icone><FaPix /></Icone>
                        <DadosPessoais>
                            <Row><Nome>{name}</Nome></Row>
                            <Row>Eldorado - Recepção</Row>
                        </DadosPessoais>
                    </Row>
                </Column>
                <Column>
                    <Row justifycontent="end"><Recebimento> +R${value ? (value / 100).toFixed(2) : '0.00'}</Recebimento></Row>
                    <Row justifycontent="end">
                        {currentValue !== 0 && <Saldo>R$ {(currentValue / 100).toFixed(2)}</Saldo>}
                    </Row>
                </Column>
            </Row>
        </Container>
    );
}

export default Pix;