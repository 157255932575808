import styled from 'styled-components';

export const Container = styled.div`
  height: 5vh;
  width: 90%;
  font-family: "Source Sans 3", sans-serif;
  font-size: 1.5vh;
  margin: 2vh 0vh;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: ${props => props.justifycontent || 'space-between'};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

`;

export const Icone = styled.div`
  color: #ff;
  background-color: #22CF37;
  border-radius:100%;
  height: 5vh;
  width: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DadosPessoais = styled.div`
  padding-left: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;


export const Recebimento = styled.h2`
  color: #fff;
  font-weight: 600;
  font-size: 2vh;
  `;

export const Saldo = styled.h2`
  color: #fff;
  font-size: 1.2vh;
  font-weight: 300;
`;

export const Nome = styled.h2`
  color: #fff;
  font-size: 115%;
  font-weight: 600;
`;