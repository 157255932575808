import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Container, H1 } from './styles';
const BasicBars = ({ mesValor, totalValue }) => {
    const labels = mesValor.map((item) => {
        const date = new Date(item.month);
        return date.toLocaleString('default', { month: 'short' });
    }
    );
    const datasets = [
        {
            label: 'Valor R$',
            data: mesValor.map((item) => item.total / 100),
            backgroundColor: '#22CF37'
        },
    ];

    let data = {
        labels,
        datasets: datasets,
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Pix',
            },
        },
    };
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    return (
        <Container>
            <H1>Valor total: R$ {totalValue.replace(".", ",")}</H1>
            <Bar data={data} options={options} height={"90%"} />
        </Container>

    );
}

export default BasicBars;
